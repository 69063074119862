html, body {
    height: 100%;
    margin: 0;
}
main.container {
    min-height: 100%;

    /* Equal to height of footer */
    /* But also accounting for potential margin-bottom of last child */
    margin-bottom: -182px;
}
footer,
.push {
    height: 182px;
}


h1, h2, h3, h4, h5, h6 {
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #222222;
    text-rendering: optimizeLegibility;
    line-height: 1.4; }
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    font-size: 60%;
    color: #6f6f6f;
    line-height: 0; }

img {
    max-width: 100%;
    height: auto; }

body {
    padding-top:51px;
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    /*line-height:1.8;*/
    font-size:16px;
}

header {
    padding-top:50px;
    padding-bottom:80px;
    background-color:#016383;
    text-align:center;
}

header h1, header h2, header h3, header h4, header h5 {
    color:white;
}

header h1 {
    font-size:64px;
    margin-bottom:0px;
}

.cf-buttons {
    margin-top:30px;
}


.cf-buttons button {
    -webkit-transition: -webkit-transform 300ms ease;
    -moz-transition: -moz-transform 300ms ease;
    -ms-transition: -ms-transform 300ms ease;
    -o-transition: -o-transform 300ms ease;
    transition: transform 300ms ease;
    transition:300ms ease;
    border-radius:0px !important;
}

.cf-buttons button {
    margin-left:6px;
    margin-right:6px;
    margin-bottom:10px;
}

.footer {
    padding-top:20px;
    padding-bottom:20px;
    color:#ffffff;
    background:gray;
    margin-bottom:0px;
    font-size:12px;
    line-height:16px;
}

.footer a {
    color:#dddddd;
}

.footer a:hover {
    color:#ffffff;
    text-decoration:underline;
}

.sponsors{
    margin-top:30px;
    margin-bottom:30px;
}

.btn-xlg {
    padding: 14px 24px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-lg {
}

.btn-lg:hover {
    /* padding: 14px 24px;
     font-size: 24px;
     line-height: 1.33;*/
}

.tutorial-window {
    position:absolute;
    width:300px;
    bottom:30px;
    right:30px;
    margin:0px;
}

.popover-widgets {
    position:absolute;
    left:280px;
    top:300px;
}

.popover-step2 {
    position:absolute;
    left:550px;
    top:180px;
}

.popover-step3 {
    position:absolute;
    left:350px;
    top:80px;
}

.popover-step4 {
    position:absolute;
    left:550px;
    top:400px;
}

.popover-step5 {
    position:absolute;
    left:550px;
    top:400px;
}

.popover-step6 {
    position:absolute;
    left:20px;
    top:90px;
}

.popover-step6 .arrow {
    left:95px;
}



.popover {
    background-color:rgba(255,255,255,0.8);
}

.popover-title {
    background-color:rgba(240,240,240,0.8);
}

.formfieldclass {
    display:block;
    width:100%;
    height:500px;
    font-family:monospace;
}

tabset > ul {
    padding-left: 5px !important;
}


.carousel-control.left,
.carousel-control.right {
     display:none;
 }

.carousel-indicators {
    bottom: 0;
}

.carousel-indicators li {
    background-color: #222;
    margin: 0 10px;
}

.carousel-indicators li.active {
    border-color: #222;
    background-color: white;
    margin: 0 10px;
}
